import React, { useMemo } from 'react';
import { useQuery } from 'utils/react-query';

import Content, { THEMES } from 'components/Globals/Layout/StubPage/Content';
import EntityUpcomingPerformances from 'components/Productions/Display/EntityUpcomingPerformances';
import EntityContactBlock from 'components/Globals/EntityContactBlock';
import EntityPerformanceCredits from 'components/Productions/Display/EntityPerformanceCredits';
import VideoListing, { VIDEO_SECTION_TYPES } from 'components/Media/Display/VideoListing';
import PhotoListing, { PHOTO_SECTION_TYPES } from 'components/Media/Display/PhotoListing';
import EntityBioSummary from 'components/Globals/EntityBioSummary';
import FeaturedArtistEntities from 'components/Artist/Display/FeaturedArtistEntities';
import FeaturedProductions from 'components/Productions/Display/FeaturedProductions';
import CreatorWorkList from 'components/Work/Display/CreatorWorkList';
import SimilarProfessions from 'components/Artist/Display/SimilarProfessions';
import AdvertisementBlock, { AD_LAYOUT } from 'components/Globals/AdvertisementBlock';
import EntitySocialLinks from 'components/Globals/EntitySocialLinks';
import OnboardingModal from 'components/Globals/Accounts/Common/OnboardingModal';
import EntityRepresentations from 'components/Manager/Display/EntityRepresentations';

import { SECTIONS, COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import queries from 'containers/Globals/queries';
import artistQueries from 'containers/Artist/queries';
import {
  PROFILE_TYPE_IDS,
  TP,
  CONTACT_TYPE,
  REPERTOIRE_SECTION_TYPES,
  PREMIUM_NUDGE_VIEW_TYPES,
  PREMIUM_NUDGE_POSITION,
  VALID_QUERY_PARAMS,
  POPUP_SOURCES,
  FEATURED_ENTITY_SECTIONS,
} from 'constants/index';
import { artistEditTabValues } from 'containers/Artist/constants';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import { createHorizontalSections } from 'utils/globals/app';
import {
  ARTIST_AGENCY_SECTIONS,
  BIOGRAPHY_SECTIONS,
  MEDIA_SECTIONS,
  PERFORMANCE_SECTION,
  REPERTOIRE_SECTIONS,
} from 'constants/consts';

import RepertoireListing from '../Repertoire/RepertoireListing';
import classes from './Home.module.scss';

const TRACKING_DATA_PROPS = {
  MUSICAL_WORK: {
    section: SECTIONS.MUSICAL_WORKS,
  },
  PERFORMANCE_CREDITS: {
    section: SECTIONS.PAST_PERFORMANCES,
    component: COMPONENTS.SEASON_SELECTOR,
  },
  SIMILAR_PROFESSIONS: {
    section: SECTIONS.SIMILAR_PROFESSIONS,
  },
  UPCOMING_PERFORMANCES: {
    section: SECTIONS.UPCOMING_PERFORMANCES,
    component: COMPONENTS.SEASON_SELECTOR,
  },
  OVERVIEW_CREDITS: {
    section: SECTIONS.OVERVIEW_CREDITS,
    component: COMPONENTS.FEATURED_PAST_PERFORMANCES,
    subComponent: SUB_COMPONENTS.PRODUCTION_SLUG,
  },
  AGENCY_REPRESENTATION_INFO: {
    section: SECTIONS.AGENCY_REPRESENTATION_INFO,
  },
  REPRESENTATION_AND_CONTACT: {
    section: SECTIONS.REPRESENTATION_AND_CONTACT,
    component: COMPONENTS.ARTIST_DIRECT_CONTACT,
  },
  DIGITAL_PRESENCE: {
    section: SECTIONS.DIGITAL_PRESENCE,
  },
  FEATURED_VIDEOS: {
    section: SECTIONS.FEATURED_VIDEOS,
    component: COMPONENTS.MEDIA_CARD,
  },
  FEATURED_PHOTOS: {
    section: SECTIONS.FEATURED_PHOTOS,
    component: COMPONENTS.MEDIA_CARD,
  },
  BIOGRAPHY: {
    section: SECTIONS.BIOGRAPHY,
  },
};

const Home = ({ entityType, entity, isEditMode = false, isPreviewMode = false }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { permissions, navigate } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();
  const showPaidSection = entity?.stats?.isPro;
  const isLoggedIn = permissions?.isLoggedIn;

  const { data: showBiography = false } = useQuery(
    queries.getEntityBioDetails({
      entityType,
      entityId: entity?.id,
      queryConfig: {
        enabled: entity?.stats?.about?.exists,
        select: response => response?.data?.length > 0,
      },
    }),
  );

  const { data: showSimilarArtist = false } = useQuery(
    artistQueries.getArtistsWithSimilarProfessions({
      id: entity?.id,
      limit: isLoggedIn ? 12 : 11,
      queryConfig: {
        select: response => response?.data?.length > 0,
        enabled: !showPaidSection,
      },
    }),
  );

  const sections = useMemo(() => {
    const { stats } = entity || {};
    const { getSubPath } = navigate.getLinkProps({ entity, entityType, edit: true });
    const isSocialContactsExists = entity?.contacts?.find(contact => contact?.contactType?.slug === CONTACT_TYPE.SNS);

    const components = [
      {
        component: CreatorWorkList,
        props: {
          enableInfiniteScrolling: false,
          limit: 5,
          isOverview: true,
        },
        ...(isEditMode && {
          sectionProps: {
            title: t(`${TP}.FN_MUSICAL_WORKS_SECTION_NAME`),
            emptySections: createHorizontalSections([
              {
                title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, {
                  section: t(`${TP}.FN_MUSICAL_WORKS_SECTION_NAME`).toLowerCase(),
                }),
                linkProps: getSubPath({
                  queryParams: { tab: artistEditTabValues.musicalWorks },
                }),
                trackingData: {
                  ...TRACKING_DATA_PROPS.MUSICAL_WORK,
                  component: SUB_COMPONENTS.ADD_MUSICAL_WORK_CTA,
                },
              },
            ]),
            editMode: {
              enabled: isEditMode,
              hasContent: stats?.creations?.composer?.exists,
              linkProps: getSubPath({
                queryParams: { tab: artistEditTabValues.musicalWorks },
              }),
            },
            enableSeeAll: false,
          },
        }),
        placement: {
          desktop: {
            column: 1,
            order: 1,
          },
          tablet: {
            column: 1,
            order: 1,
          },
          mobile: {
            column: 1,
            order: 1,
          },
        },
        showSection: isEditMode || stats?.creations?.composer?.exists,
      },
      {
        component: SimilarProfessions,
        props: {
          limit: 6,
        },
        sectionProps: {
          ...(isMobile && { theme: THEMES.DARK }),
          ...(isEditMode && {
            editMode: {
              enabled: isEditMode,
              hasContent: true,
              trackingData: TRACKING_DATA_PROPS.SIMILAR_PROFESSIONS,
            },
            ...(!showPaidSection && {
              premiumSection: {
                type: isMobile ? PREMIUM_NUDGE_VIEW_TYPES.DARK : PREMIUM_NUDGE_VIEW_TYPES.DEFAULT,
                ...(isMobile && { placement: PREMIUM_NUDGE_POSITION.TOP }),
                enabled: true,
                isHidden: true,
                hideDescriptionIcon: true,
                description: t('SIMILAR_ARTIST_SECTION_DESCRIPTION'),
                nudgeSourceType: POPUP_SOURCES.DISCOVER_ARTIST || '',
                trackingData: TRACKING_DATA_PROPS.SIMILAR_PROFESSIONS,
              },
              styles: { root: classes.discoverSimilarArtist },
              enableSeeAll: false,
            }),
          }),
        },
        placement: {
          desktop: {
            column: 3,
            order: 1,
          },
          tablet: {
            column: 2,
            order: stats?.productions?.future?.exists ? 2 : 1,
          },
          mobile: {
            column: 1,
            order: 3,
            hide: !stats?.productions?.future?.exists,
          },
        },
        showSection: showSimilarArtist && !showPaidSection,
      },
      {
        component: EntityPerformanceCredits,
        props: {
          infinite: false,
          isOverview: true,
        },
        sectionProps: {
          enableSeeAll: !isMobile,
          ...(isEditMode && {
            title: t(`${TP}.FN_CREDITS`),
            emptySections: createHorizontalSections([
              {
                title: t(`${TP}.FN_ADD_PAST_PERFORMANCES`),
                linkProps: getSubPath({
                  queryParams: {
                    tab: artistEditTabValues.schedule,
                    [VALID_QUERY_PARAMS.OPEN_MODAL]: PERFORMANCE_SECTION.ADD_PERFORMANCE,
                  },
                }),
                trackingData: {
                  ...TRACKING_DATA_PROPS.PERFORMANCE_CREDITS,
                  subComponent: SUB_COMPONENTS.ADD_PRODUCTION_CTA,
                },
              },
            ]),
            editMode: {
              enabled: isEditMode,
              hasContent: stats?.productions?.past?.exists,
              trackingData: TRACKING_DATA_PROPS.PERFORMANCE_CREDITS,
              linkProps: getSubPath({
                queryParams: {
                  tab: artistEditTabValues.schedule,
                },
              }),
            },
            addMore: {
              enabled: isEditMode && stats?.productions?.past?.exists,
              emptySections: createHorizontalSections([
                {
                  title: t(`${TP}.ADD_MORE_PERFORMANCES`),
                  linkProps: getSubPath({
                    queryParams: {
                      tab: artistEditTabValues.schedule,
                      [VALID_QUERY_PARAMS.OPEN_MODAL]: PERFORMANCE_SECTION.ADD_PERFORMANCE,
                    },
                  }),
                  trackingData: {
                    ...TRACKING_DATA_PROPS.PERFORMANCE_CREDITS,
                    subComponent: SUB_COMPONENTS.ADD_PRODUCTION_CTA,
                  },
                },
              ]),
            },
            enableSeeAll: false,
          }),
        },
        placement: {
          desktop: {
            column: 1,
            order: 1,
          },
          tablet: {
            column: 1,
            order: 1,
          },
          mobile: {
            column: 1,
            order: 3,
          },
        },
        showSection: isEditMode || stats?.productions?.past?.exists,
      },
      {
        component: EntityUpcomingPerformances,
        props: {
          infinite: false,
          trackingData: {
            section: SECTIONS.UPCOMING_PERFORMANCES,
            component: COMPONENTS.SEASON_SELECTOR,
            subComponent: SUB_COMPONENTS.ADD_PRODUCTION_CTA,
          },
          isOverview: true,
        },
        sectionProps: {
          enableSeeAll: false,
          ...(isEditMode && {
            title: t(`${TP}.FN_UPCOMING_PERFORMANCES`),
            emptySections: createHorizontalSections([
              {
                title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, { section: t(`${TP}.FN_UPCOMING_PERFORMANCES`) }),
                linkProps: getSubPath({
                  queryParams: {
                    tab: artistEditTabValues.schedule,
                    [VALID_QUERY_PARAMS.OPEN_MODAL]: PERFORMANCE_SECTION.ADD_PERFORMANCE,
                  },
                }),
                trackingData: {
                  ...TRACKING_DATA_PROPS.UPCOMING_PERFORMANCES,
                  subComponent: SUB_COMPONENTS.ADD_PRODUCTION_CTA,
                },
              },
            ]),
            editMode: {
              enabled: isEditMode,
              hasContent: stats?.productions?.future?.exists,
              trackingData: TRACKING_DATA_PROPS.UPCOMING_PERFORMANCES,
              linkProps: getSubPath({
                queryParams: {
                  tab: artistEditTabValues.schedule,
                },
              }),
            },
            addMore: {
              enabled: isEditMode && stats?.productions?.future?.exists,
              emptySections: createHorizontalSections([
                {
                  title: t(`${TP}.ADD_MORE_PERFORMANCES`),
                  linkProps: getSubPath({
                    queryParams: {
                      tab: artistEditTabValues.schedule,
                      [VALID_QUERY_PARAMS.OPEN_MODAL]: PERFORMANCE_SECTION.ADD_PERFORMANCE,
                    },
                  }),
                  trackingData: {
                    ...TRACKING_DATA_PROPS.UPCOMING_PERFORMANCES,
                    subComponent: SUB_COMPONENTS.ADD_PRODUCTION_CTA,
                  },
                },
              ]),
            },
          }),
        },
        placement: {
          desktop: {
            column: 2,
            order: 1,
          },
          tablet: {
            column: 2,
            order: 1,
          },
          mobile: {
            column: 1,
            order: !showBiography && !isSocialContactsExists ? 1 : 2,
          },
        },
        showSection: isEditMode || stats?.productions?.future?.exists,
      },
      {
        component: FeaturedProductions,
        props: {
          limit: 5,
          trackingData: TRACKING_DATA_PROPS.OVERVIEW_CREDITS,
        },
        sectionProps: {
          title: t(`${TP}.FN_FEATURED_PAST_PERFORMANCES`),
          ...(isEditMode && {
            emptySections: createHorizontalSections([
              {
                title: t(`${TP}.FN_ADD_PAST_PERFORMANCES`),
                linkProps: getSubPath({
                  queryParams: {
                    tab: artistEditTabValues.schedule,
                    [VALID_QUERY_PARAMS.OPEN_MODAL]: PERFORMANCE_SECTION.ADD_PERFORMANCE,
                  },
                }),
                trackingData: {
                  ...TRACKING_DATA_PROPS.OVERVIEW_CREDITS,
                  subComponent: SUB_COMPONENTS.ADD_PRODUCTION_CTA,
                },
              },
            ]),
            editMode: {
              enabled: isEditMode,
              hasContent: stats?.productions?.past?.exists && stats?.media?.images?.production?.past?.exists,
              trackingData: TRACKING_DATA_PROPS.OVERVIEW_CREDITS,
              linkProps: getSubPath({
                queryParams: {
                  tab: artistEditTabValues.schedule,
                },
              }),
            },
            enableSeeAll: false,
          }),
        },
        placement: {
          desktop: {
            column: 1,
            order: 3,
          },
          tablet: {
            column: 1,
            order: 3,
          },
          mobile: {
            column: 1,
            order: 9,
          },
        },
        showSection: isEditMode || (stats?.productions?.past?.exists && stats?.media?.images?.production?.past?.exists),
      },
      {
        component: FeaturedArtistEntities,
        props: {
          section: FEATURED_ENTITY_SECTIONS.ORGANIZATIONS,
          isEditMode,
        },
        sectionProps: {
          ...(isMobile && { theme: THEMES.DARK }),
          ...(isEditMode && {
            editMode: {
              enabled: isEditMode,
              hasContent: true,
            },
          }),
        },
        placement: {
          desktop: {
            column: 1,
            order: 2,
          },
          tablet: {
            column: 1,
            order: 2,
          },
          mobile: {
            column: 1,
            order: 6,
          },
        },
        showSection:
          isEditMode ||
          (stats?.preferredEntities?.[FEATURED_ENTITY_SECTIONS.ORGANIZATIONS]?.visibility && showPaidSection),
      },
      {
        component: FeaturedArtistEntities,
        props: {
          section: FEATURED_ENTITY_SECTIONS.ARTISTS,
          isEditMode,
        },
        sectionProps: {
          ...(isMobile && { marginTopNone: true, theme: THEMES.DARK }),
          ...(isEditMode && {
            editMode: {
              enabled: isEditMode,
              hasContent: true,
            },
          }),
        },
        placement: {
          desktop: {
            column: 1,
            order: 2,
          },
          tablet: {
            column: 1,
            order: 2,
          },
          mobile: {
            column: 1,
            order: 6,
          },
        },
        showSection:
          isEditMode || (stats?.preferredEntities?.[FEATURED_ENTITY_SECTIONS.ARTISTS]?.visibility && showPaidSection),
      },
      {
        component: FeaturedArtistEntities,
        props: {
          section: FEATURED_ENTITY_SECTIONS.VENUES,
          isEditMode,
        },
        sectionProps: {
          ...(isMobile && { marginTopNone: true, theme: THEMES.DARK }),
          ...(isEditMode && {
            editMode: {
              enabled: isEditMode,
              hasContent: true,
            },
          }),
        },
        placement: {
          desktop: {
            column: 1,
            order: 2,
          },
          tablet: {
            column: 1,
            order: 2,
          },
          mobile: {
            column: 1,
            order: 6,
          },
        },
        showSection:
          isEditMode || (stats?.preferredEntities?.[FEATURED_ENTITY_SECTIONS.VENUES]?.visibility && showPaidSection),
      },
      {
        component: EntityContactBlock,
        props: {
          isOverview: true,
          isEditMode,
        },
        sectionProps: {
          marginTopSmall: !isEditMode,
          enableSeeAll: !isEditMode,
        },
        placement: {
          desktop: {
            column: 3,
            order: showPaidSection ? 1 : 2,
          },
          tablet: {
            column: 2,
            order: showPaidSection ? 1 : 3,
          },
          mobile: {
            column: 1,
            order: 1,
          },
        },
        showSection:
          !isEditMode && (stats?.contacts?.exists || entity?.stats?.agencies?.exists || !!isSocialContactsExists),
      },
      {
        component: EntityRepresentations,
        props: {
          isOverview: true,
          isEditMode,
        },
        sectionProps: {
          marginTopSmall: !isEditMode,
          enableSeeAll: !isEditMode,
          ...(isEditMode && {
            editMode: {
              hasContent: entity?.stats?.agencies?.exists,
              enabled: isEditMode,
              linkProps: getSubPath({
                queryParams: { tab: artistEditTabValues.contactInfo },
                scrollTo: ARTIST_AGENCY_SECTIONS.AGENCY_REPRESENTATION_INFO,
              }),
              trackingData: TRACKING_DATA_PROPS.AGENCY_REPRESENTATION_INFO,
            },
            emptySections: createHorizontalSections([
              {
                title: t('ADD_REPRESENTATION_INFO'),
                linkProps: getSubPath({
                  queryParams: { tab: artistEditTabValues.contactInfo },
                  scrollTo: ARTIST_AGENCY_SECTIONS.AGENCY_REPRESENTATION_INFO,
                }),
                trackingData: {
                  ...TRACKING_DATA_PROPS.AGENCY_REPRESENTATION_INFO,
                  subComponent: SUB_COMPONENTS.ADD_CTA,
                },
              },
            ]),
            ...(!entity?.stats?.agencies?.exists && {
              title: t('AGENCY_REPRESENTATION'),
              description: t('AGENCY_REPRESENTATION_TITLE_DESCRIPTION'),
            }),
          }),
        },
        placement: {
          desktop: {
            column: 3,
            order: showPaidSection ? 1 : 2,
          },
          tablet: {
            column: 2,
            order: showPaidSection ? 1 : 3,
          },
          mobile: {
            column: 1,
            order: 1,
          },
        },
        showSection: isEditMode,
      },
      {
        component: EntityContactBlock,
        props: {
          isOverview: true,
          isEditMode,
        },
        sectionProps: {
          marginTopSmall: !isEditMode,
          ...(isEditMode && {
            enableSeeAll: false,
            editMode: {
              hasContent: stats?.contacts?.nonSocial?.exists,
              enabled: true,
              linkProps: getSubPath({
                queryParams: { tab: artistEditTabValues.contactInfo },
              }),
              trackingData: TRACKING_DATA_PROPS.REPRESENTATION_AND_CONTACT,
            },
            emptySections: createHorizontalSections([
              {
                title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, {
                  section: t(`${TP}.FN_ARTIST_DIRECT_CONTACT_TITLE`)?.toLowerCase(),
                }),
                linkProps: getSubPath({
                  queryParams: { tab: artistEditTabValues.contactInfo },
                }),
                trackingData: {
                  ...TRACKING_DATA_PROPS.REPRESENTATION_AND_CONTACT,
                  subComponent: SUB_COMPONENTS.ADD_CTA,
                },
              },
            ]),
            ...(!stats?.contacts?.nonSocial?.exists && {
              title: t(`${TP}.FN_ARTIST_DIRECT_CONTACT_TITLE`),
              description: t('AGENCY_REPRESENTATION_TITLE_DESCRIPTION'),
            }),
          }),
        },
        placement: {
          desktop: {
            column: 3,
            order: showPaidSection ? 1 : 2,
          },
          tablet: {
            column: 2,
            order: showPaidSection ? 1 : 3,
          },
          mobile: {
            column: 1,
            order: 1,
          },
        },
        showSection: isEditMode,
      },
      {
        component: EntitySocialLinks,
        props: {
          inline: true,
          isEditMode,
        },
        sectionProps: {
          marginTopSmall: !isEditMode,
          ...(isEditMode && {
            editMode: {
              hasContent: isSocialContactsExists,
              enabled: isEditMode,
              linkProps: getSubPath({
                queryParams: { tab: artistEditTabValues.digitalPresence },
              }),
              trackingData: TRACKING_DATA_PROPS.DIGITAL_PRESENCE,
            },
            emptySections: createHorizontalSections([
              {
                title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, {
                  section: t(`${TP}.FN_TAB_DIGITAL_PRESENCE`)?.toLowerCase(),
                }),
                linkProps: getSubPath({
                  queryParams: { tab: artistEditTabValues.digitalPresence },
                }),
                trackingData: {
                  ...TRACKING_DATA_PROPS.DIGITAL_PRESENCE,
                  component: SUB_COMPONENTS.ADD_CTA,
                },
              },
            ]),
            ...(!isSocialContactsExists && {
              title: t(`${TP}.FN_TAB_DIGITAL_PRESENCE`),
              description: t('AGENCY_REPRESENTATION_TITLE_DESCRIPTION'),
            }),
          }),
        },
        placement: {
          desktop: {
            column: 3,
            order: showPaidSection ? 2 : 3,
          },
          tablet: {
            hide: true,
          },
          mobile: {
            column: 1,
            order: 1,
            hide: true,
          },
        },
        showSection: isEditMode,
      },
      {
        component: VideoListing,
        props: {
          type: stats?.media?.videos?.featured?.exists ? VIDEO_SECTION_TYPES.FEATURED : VIDEO_SECTION_TYPES.PRODUCTION,
          limit: 3,
          infinite: false,
          title: t('FEATURED_VIDEOS'),
          isOverview: true,
          trackingData: TRACKING_DATA_PROPS.FEATURED_VIDEOS,
        },
        ...(isEditMode && {
          sectionProps: {
            title: t('FEATURED_VIDEOS'),
            emptySections: createHorizontalSections([
              {
                title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, { section: t(`PERFORMANCES`) }),
                linkProps: getSubPath({
                  queryParams: {
                    tab: artistEditTabValues.schedule,
                    [VALID_QUERY_PARAMS.OPEN_MODAL]: PERFORMANCE_SECTION.ADD_PERFORMANCE,
                  },
                }),
                trackingData: {
                  ...TRACKING_DATA_PROPS.FEATURED_VIDEOS,
                  subComponent: SUB_COMPONENTS.ADD_PRODUCTION_CTA,
                },
              },
              {
                title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, { section: t(`${TP}.m_VIDEOS`) }),
                linkProps: getSubPath({
                  queryParams: {
                    tab: artistEditTabValues.videos,
                    [VALID_QUERY_PARAMS.OPEN_MODAL]: MEDIA_SECTIONS.INTRO_SHOWREEL,
                  },
                  scrollTo: MEDIA_SECTIONS.INTRO_SHOWREEL,
                }),
                trackingData: { ...TRACKING_DATA_PROPS.FEATURED_VIDEOS, subComponent: SUB_COMPONENTS.ADD_VIDEOS_CTA },
              },
            ]),
            editMode: {
              hasContent: stats?.media?.videos?.production?.exists || stats?.media?.videos?.featured?.exists,
              enabled: isEditMode,
              trackingData: TRACKING_DATA_PROPS.FEATURED_VIDEOS,
              linkProps: getSubPath({
                queryParams: { tab: artistEditTabValues.videos },
              }),
            },
            ...(!(stats?.media?.videos?.production?.exists || stats?.media?.videos?.featured?.exists) && {
              description: t(`${TP}.FN_FEATURED_VIDEOS_DESCRIPTION`),
            }),
            enableSeeAll: false,
          },
        }),
        placement: {
          desktop: {
            column: 2,
            order: 2,
          },
          tablet: {
            column: 2,
            order: 5,
          },
          mobile: {
            column: 1,
            order: 7,
          },
        },
        showSection: isEditMode || stats?.media?.videos?.production?.exists || stats?.media?.videos?.featured?.exists,
      },
      {
        component: SimilarProfessions,
        sectionProps: {
          ...(isMobile && { theme: THEMES.DARK }),
          ...(isEditMode && {
            editMode: {
              enabled: isEditMode,
              hasContent: true,
              trackingData: TRACKING_DATA_PROPS.SIMILAR_PROFESSIONS,
            },
            ...(!showPaidSection && {
              premiumSection: {
                type: isMobile ? PREMIUM_NUDGE_VIEW_TYPES.DARK : PREMIUM_NUDGE_VIEW_TYPES.DEFAULT,
                ...(isMobile && { placement: PREMIUM_NUDGE_POSITION.TOP }),
                enabled: true,
                isHidden: true,
                hideDescriptionIcon: true,
                description: t(`${TP}.FN_OTHER_ARTIST_SECTION_DESCRIPTION`),
                nudgeSourceType: POPUP_SOURCES.DISCOVER_ARTIST || '',
                trackingData: TRACKING_DATA_PROPS.SIMILAR_PROFESSIONS,
              },
              styles: { root: classes.discoverSimilarArtist },
              enableSeeAll: false,
            }),
          }),
        },
        showSection: isEditMode || !showPaidSection,
        placement: {
          desktop: { hide: true },
          tablet: { hide: true },
          mobile: {
            column: 1,
            order: 7,
            hide:
              !stats?.media?.videos?.production?.exists ||
              !(stats?.productions?.past?.exists && stats?.media?.images?.production?.past?.exists),
          },
        },
      },
      {
        component: PhotoListing,
        props: {
          type: stats?.media?.images?.featured?.exists ? PHOTO_SECTION_TYPES.FEATURED : PHOTO_SECTION_TYPES.PRODUCTION,
          limit: 3,
          infinite: false,
          title: t('FEATURED_PHOTOS'),
          isOverview: true,
          trackingData: TRACKING_DATA_PROPS.FEATURED_PHOTOS,
        },
        ...(isEditMode && {
          sectionProps: {
            title: t('FEATURED_PHOTOS'),
            emptySections: createHorizontalSections([
              {
                title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, { section: t(`PERFORMANCES`) }),
                linkProps: getSubPath({
                  queryParams: {
                    tab: artistEditTabValues.schedule,
                    [VALID_QUERY_PARAMS.OPEN_MODAL]: PERFORMANCE_SECTION.ADD_PERFORMANCE,
                  },
                }),
                trackingData: {
                  ...TRACKING_DATA_PROPS.FEATURED_PHOTOS,
                  subComponent: SUB_COMPONENTS.ADD_PRODUCTION_CTA,
                },
              },
              {
                title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, { section: t(`${TP}.m_IMAGES`)?.toLowerCase() }),
                linkProps: getSubPath({
                  queryParams: {
                    tab: artistEditTabValues.photos,
                    [VALID_QUERY_PARAMS.OPEN_MODAL]: MEDIA_SECTIONS.GENERAL_PHOTOS,
                  },
                }),
                trackingData: {
                  ...TRACKING_DATA_PROPS.FEATURED_PHOTOS,
                  subComponent: SUB_COMPONENTS.ADD_PHOTOS_CTA,
                },
              },
            ]),
            editMode: {
              trackingData: TRACKING_DATA_PROPS.FEATURED_PHOTOS,
              hasContent: stats?.media?.images?.production?.exists || stats?.media?.images?.featured?.exists,
              enabled: isEditMode,
              linkProps: getSubPath({
                queryParams: {
                  tab: artistEditTabValues.photos,
                },
                scrollTo: MEDIA_SECTIONS.PART_OF_PRODUCTION,
              }),
            },
            ...(!(stats?.media?.images?.production?.exists || stats?.media?.images?.featured?.exists) && {
              description: t(`${TP}.FN_FEATURED_PHOTOS_DESCRIPTION`),
            }),
            enableSeeAll: false,
          },
        }),
        placement: {
          desktop: {
            column: 2,
            order: 3,
          },
          tablet: {
            column: 2,
            order: 6,
          },
          mobile: {
            column: 1,
            order: 8,
          },
        },
        showSection: isEditMode || stats?.media?.images?.production?.exists || stats?.media?.images?.featured?.exists,
      },
      {
        component: EntityBioSummary,
        props: {
          showMinDescription: true,
          isEditMode,
          trackingData: TRACKING_DATA_PROPS.BIOGRAPHY,
          isOverview: true,
        },
        sectionProps: {
          enableSeeAll: false,
          marginTopSixteen: stats?.contacts?.exists,
          ...(isEditMode && {
            title: t(`${TP}.m_BIO`),
            emptySections: createHorizontalSections([
              {
                title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, {
                  section: t(`${TP}.m_BIO`)?.toLowerCase(),
                }),
                linkProps: getSubPath({
                  queryParams: {
                    tab: artistEditTabValues.biography,
                    [VALID_QUERY_PARAMS.OPEN_MODAL]: BIOGRAPHY_SECTIONS.ADD_BIOGRAPHY,
                  },
                }),
                trackingData: {
                  ...TRACKING_DATA_PROPS.BIOGRAPHY,
                  component: SUB_COMPONENTS.ADD_CTA,
                },
              },
            ]),
            editMode: {
              trackingData: TRACKING_DATA_PROPS.BIOGRAPHY,
              enabled: isEditMode,
              hasContent: showBiography,
              linkProps: getSubPath({ queryParams: { tab: artistEditTabValues.biography } }),
            },
            ...(!showBiography && {
              description: t(`${TP}.FN_BIOGRAPHY_DESCRIPTION`),
            }),
            enableSeeAll: false,
          }),
        },
        placement: {
          desktop: {
            column: 3,
            order: showPaidSection ? 2 : 3,
          },
          tablet: {
            column: 2,
            order: 2,
            hide: !isEditMode,
          },
          mobile: { column: 1, hide: !isEditMode },
        },
        showSection: isEditMode || !!showBiography,
      },
      {
        component: RepertoireListing,
        props: {
          type: REPERTOIRE_SECTION_TYPES.FUTURE,
          isEditMode,
          isOverview: true,
        },
        ...(isEditMode && {
          sectionProps: {
            title: t(`${TP}.FN_FUTURE_REPERTOIRE`),
            editMode: {
              enabled: true,
              hasContent: true,
              linkProps: getSubPath({
                queryParams: { tab: artistEditTabValues.repertoire },
              }),
            },
            enableSeeAll: false,
          },
        }),
        placement: {
          desktop: {
            column: 3,
            order: 3,
          },
          tablet: {
            column: 2,
            order: 4,
          },
          mobile: {
            column: 1,
            order: 4,
          },
        },
        showSection: isEditMode || (stats?.repertoire?.future?.exists && showPaidSection),
      },
      {
        component: RepertoireListing,
        props: {
          type: REPERTOIRE_SECTION_TYPES.PAST,
          isEditMode,
          isOverview: true,
        },
        ...(isEditMode && {
          sectionProps: {
            title: t(`${TP}.m_LISTREPERTOIRE`),
            editMode: {
              enabled: true,
              hasContent: true,
              linkProps: getSubPath({
                queryParams: { tab: artistEditTabValues.repertoire },
                scrollTo: REPERTOIRE_SECTIONS.REPERTOIRE,
              }),
            },
            ...(!stats?.repertoire?.past?.exists && {
              description: t(`${TP}.FN_REPERTOIRE_DESCRIPTION`),
            }),
            enableSeeAll: false,
          },
        }),
        placement: {
          desktop: {
            column: 3,
            order: 4,
          },
          tablet: {
            column: 2,
            order: 4,
          },
          mobile: {
            column: 1,
            order: 5,
          },
        },
        showSection:
          isEditMode ||
          (stats?.repertoire?.past?.exists && showPaidSection && stats?.customizations?.repertoire?.visibility),
      },
      {
        component: SimilarProfessions,
        sectionProps: {
          ...(isMobile && { theme: THEMES.DARK }),
          ...(isEditMode && {
            editMode: {
              enabled: isEditMode,
              hasContent: true,
              trackingData: TRACKING_DATA_PROPS.SIMILAR_PROFESSIONS,
            },
            ...(!showPaidSection && {
              premiumSection: {
                type: isMobile ? PREMIUM_NUDGE_VIEW_TYPES.DARK : PREMIUM_NUDGE_VIEW_TYPES.DEFAULT,
                ...(isMobile && { placement: PREMIUM_NUDGE_POSITION.TOP }),
                enabled: true,
                isHidden: true,
                hideDescriptionIcon: true,
                description: t(`${TP}.FN_OTHER_ARTIST_SECTION_DESCRIPTION`),
                nudgeSourceType: POPUP_SOURCES.DISCOVER_ARTIST || '',
                trackingData: TRACKING_DATA_PROPS.SIMILAR_PROFESSIONS,
              },
              styles: { root: classes.discoverSimilarArtist },
              enableSeeAll: false,
            }),
          }),
        },
        showSection: isEditMode || !showPaidSection,
        placement: {
          desktop: { hide: true },
          tablet: { hide: true },
          mobile: {
            column: 1,
            order: 10,
          },
        },
      },
      {
        component: AdvertisementBlock,
        sectionProps: {
          theme: THEMES.DARK,
          marginTopNone: true,
        },
        props: {
          layout: AD_LAYOUT.HORIZONTAL,
        },
        placement: {
          desktop: {
            hide: true,
          },
          tablet: {
            hide: true,
          },
          mobile: {
            column: 1,
            order: 11,
          },
        },
        showSection: !showPaidSection && !isEditMode,
      },
      {
        component: AdvertisementBlock,
        placement: {
          desktop: {
            column: 3,
            order: 4,
          },
          tablet: {
            column: 2,
            order: 6,
          },
          mobile: {
            column: 1,
            order: 12,
          },
        },
        showSection: !showPaidSection && !isEditMode,
      },
    ];

    return components;
  }, [entity, navigate, entityType, isEditMode, t, isMobile, showPaidSection, showSimilarArtist, showBiography]);

  return (
    <>
      <Content entity={entity} entityType={entityType} sections={sections} />
      {permissions?.isTargetMyProfile && !isPreviewMode && (
        <OnboardingModal profileId={entity?.id} profileTypeId={PROFILE_TYPE_IDS.ARTIST} />
      )}
    </>
  );
};

export default Home;
