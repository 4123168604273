import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';

import SectionBlock from 'components/Globals/SectionBlock';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import InfiniteListPage, { PageLoading } from 'components/Globals/Layout/InfiniteListPage';
import PreviewEntityBlock from 'components/Globals/PreviewEntityBlock';
import Loader from 'components/uiLibrary/Loader';
import NoResult from 'components/Globals/NoResult';
import MediaAttribution from 'components/Media/MediaAttribution';
import { COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import {
  TP,
  ENTITY_TYPE,
  ENTITY_MAIN_TABS,
  ENTITY_DETAILS_TAB,
  PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
  UPCOMING_PRODUCTIONS_MEDIA_SORT_ORDER,
  SORT_OPTION_VALUES,
} from 'constants/index';
import usePageContext from 'utils/hooks/usePageContext';
import { getProductionTitleWorkNames } from 'utils/productions';
import { transformUpcomingDateFilters, transformPastDateFilters } from 'utils/globals/filters';
import { useQuery } from 'utils/react-query';
import { getMediaSource } from 'utils/media';
import withLightbox from 'utils/hocs/withLightbox';
import { useTranslation } from 'src/i18n';

import queries from 'containers/Media/queries';

import CardWithProductionInfo from '../CardWithProductionInfo';
import classes from './PhotoListing.module.scss';

export const PHOTO_SECTION_TYPES = {
  FEATURED: 'featured',
  PAST: 'past',
  UPCOMING: 'upcoming',
  PRODUCTION: 'production',
};

const FEATURED_SECTION_TITLES = {
  [ENTITY_TYPE.ARTIST]: `${TP}.FN_PROFILE_PHOTOS_SECTION`,
  [ENTITY_TYPE.ORGANIZATION]: `${TP}.FN_ORGANIZATION_PHOTOS_SECTION_TITLE`,
  [ENTITY_TYPE.PRODUCTION]: `${TP}.FN_TAB_PHOTOS`,
};

const PHOTO_SECTION_SORT_ORDER = {
  [PHOTO_SECTION_TYPES.PAST]: PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
  [PHOTO_SECTION_TYPES.UPCOMING]: UPCOMING_PRODUCTIONS_MEDIA_SORT_ORDER,
  [PHOTO_SECTION_TYPES.PRODUCTION]: PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
};

const PhotoGroup = withLightbox(
  ({ photos, fullWidthCard = false, showLightbox, showProductionInfo = false, trackingData }) => {
    const openLightbox = mediaId => {
      showLightbox({ data: photos, mediaId, trackingData });
    };

    return (
      <div className={classnames(classes.group, { [classes.fullWidthGroup]: fullWidthCard })}>
        {photos?.map(photo => (
          <CardWithProductionInfo
            key={photo?.id}
            production={photo?.production}
            disabled={!showProductionInfo}
            trackingData={trackingData}
            styles={{
              footer: classes.cardFooter,
              root: classnames(classes.card, {
                [classes.fullWidthCard]: !!fullWidthCard,
              }),
            }}
          >
            <>
              <Image
                src={getMediaSource(photo)}
                blurHash={photo?.blurHash}
                className={classes.image}
                transformations={
                  photo?.isPortrait ? TRANSFORMATIONS.GALLERY_PORTRAIT_VIEW : TRANSFORMATIONS.GALLERY_LANDSCAPE_VIEW
                }
                {...(!fullWidthCard && { height: 192 })}
                fitWidth={fullWidthCard}
                onClick={() => openLightbox(photo?.id)}
                trackingData={trackingData}
                useIntersectionObserver
                disableNextImage
                lazy
                {...(photo?.title && { alt: photo?.title, title: photo?.title })}
              />
              <MediaAttribution
                className={classnames(classes.mediaAttribution, {
                  [classes.withProductionInfo]: showProductionInfo && photo?.production?.id,
                })}
                attribution={photo}
              />
            </>
          </CardWithProductionInfo>
        ))}
      </div>
    );
  },
);

const ProductionPhotos = ({
  title,
  type,
  limit,
  distinctLimit,
  infinite,
  sectionProps = {},
  isOverview,
  trackingData,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { entity, entityType, entityId, filterParams, navigate, mainPath, hasAppliedFilters } = usePageContext();
  const isTablet = useMediaQuery('(max-width: 1280px) and (min-width: 925px)');
  const isTabletLayout = isTablet || isOverview;

  const isMobile = useMediaQuery('(max-width: 925px) and (min-width: 300px)');
  const isMobileLayout = isMobile || isOverview;

  const filters = useMemo(() => {
    if (type === PHOTO_SECTION_TYPES.PRODUCTION) {
      return {
        ...filterParams,
        sort: SORT_OPTION_VALUES.CUSTOM_DATE,
      };
    }

    if (type === PHOTO_SECTION_TYPES.PAST) {
      return transformPastDateFilters(filterParams);
    }

    return transformUpcomingDateFilters(filterParams);
  }, [type, filterParams]);

  const linkProps = navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.IMAGES });

  // eslint-disable-next-line no-nested-ternary
  const renderMaxCount = isMobileLayout ? 1 : isTabletLayout ? 2 : 3;

  const mediaCount = useMemo(() => {
    if ((mainPath && !isOverview) || hasAppliedFilters) {
      return null;
    }

    return entity?.stats?.media?.images?.total;
  }, [mainPath, hasAppliedFilters, entity, isOverview]);

  return (
    <InfiniteListPage
      query={queries.getProductionPhotos({
        entityType,
        entityId,
        filters,
        limit,
        distinctLimit,
        sort: PHOTO_SECTION_SORT_ORDER[type],
      })}
      disabled={!infinite}
      updateAppliedFilters={!!mainPath && !isOverview}
      disableQueryUpdate
    >
      {({ count, isLoading, pages }) => (
        <SectionBlock
          title={title}
          count={mediaCount || count}
          linkProps={{
            ...linkProps,
            ...(isOverview && { title: t(`${TP}.FN_VIEW_ALL_PHOTOS`) }),
          }}
          enableSeeAll={isOverview}
          enableViewAllButton={isOverview}
          seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
          {...sectionProps}
        >
          {pages?.map(({ data: pageData }) => (
            <>
              {pageData?.map(({ production, data: photos, total: totalCount = 0 }, index) => {
                const name = getProductionTitleWorkNames(production);
                const prodTitle = name?.customName || name?.workNames?.[0];
                const showCtaLabel = isMobileLayout ? totalCount >= 1 : totalCount > 3;

                return (
                  <PreviewEntityBlock
                    key={`${production?.id}_${index}`}
                    production={production}
                    linkProps={navigate.getLinkProps({
                      entityType: ENTITY_TYPE.PRODUCTION,
                      entity: production,
                      path: ENTITY_MAIN_TABS.IMAGES,
                      title: t(`${TP}.FN_VIEW_MORE_PHOTOS_FROM`, {
                        entityName: prodTitle,
                      }),
                    })}
                    {...(showCtaLabel && {
                      ctaLabel: t(`${TP}.FN_VIEW_X_MORE_PHOTOS`),
                    })}
                    disabled={isOverview}
                    hideLinkProps={totalCount < renderMaxCount}
                    trackingData={trackingData}
                    ctaTrackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.VIEW_MORE_CTA }}
                  >
                    <PhotoGroup
                      photos={photos?.slice(0, renderMaxCount)}
                      fullWidthCard={isOverview || isMobileLayout}
                      showProductionInfo={!isOverview}
                      trackingData={trackingData}
                    />
                  </PreviewEntityBlock>
                );
              })}
            </>
          ))}
          {!isLoading && !count && <NoResult title={t(`${TP}.NO_RESULTS`, { ns: 'NS_APP_GLOBALS' })} />}
          <PageLoading defaultLoader />
        </SectionBlock>
      )}
    </InfiniteListPage>
  );
};

const FeaturedPhotos = ({ entity, title, limit = 3, sectionProps = {}, trackingData, isOverview }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { entityType, entityId, filterParams, navigate, mainPath, hasAppliedFilters } = usePageContext();
  const { data, isLoading } = useQuery(
    queries.getFeaturedPhotos({ entityId, entityType, filters: filterParams, limit }),
  );
  const { data: photos, total } = data || {};
  const isMobile = useMediaQuery('(max-width: 925px) and (min-width: 300px)');
  const isMobileLayout = isMobile || isOverview;

  const linkProps = useMemo(() => {
    let path = ENTITY_MAIN_TABS.IMAGES;

    if (mainPath === ENTITY_MAIN_TABS.IMAGES) {
      path += `/${ENTITY_DETAILS_TAB.GENERAL}`;
    }

    return navigate.getLinkProps({ entityType, entity, path, pro: false });
  }, [navigate, mainPath, entityType, entity]);

  const mediaCount = useMemo(() => {
    if ((mainPath && !isOverview) || hasAppliedFilters) {
      return null;
    }

    return entity?.stats?.media?.images?.total;
  }, [mainPath, hasAppliedFilters, entity, isOverview]);

  return (
    <SectionBlock
      title={title}
      count={mediaCount || total}
      linkProps={linkProps}
      enableSeeAll={isMobileLayout}
      seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
      {...sectionProps}
    >
      <PreviewEntityBlock
        linkProps={linkProps}
        ctaLabel={t(`${TP}.FN_VIEW_X_MORE_PHOTOS`, { count: total })}
        disabled={isMobileLayout}
        hideLinkProps={total <= 3}
        trackingData={trackingData}
        ctaTrackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.VIEW_MORE_CTA }}
      >
        <PhotoGroup photos={photos} fullWidthCard={isOverview || isMobileLayout} trackingData={trackingData} />
      </PreviewEntityBlock>
      {!isLoading && !total && <NoResult title={t(`${TP}.NO_RESULTS`, { ns: 'NS_APP_GLOBALS' })} />}
      {isLoading && <Loader />}
    </SectionBlock>
  );
};

const PhotoListing = ({
  title,
  entity,
  type,
  limit,
  distinctLimit,
  infinite = true,
  sectionProps,
  isOverview = false,
  trackingData,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { entityType } = usePageContext();

  if (type === PHOTO_SECTION_TYPES.FEATURED) {
    return (
      <FeaturedPhotos
        title={title || t(FEATURED_SECTION_TITLES[entityType])}
        entity={entity}
        limit={limit}
        sectionProps={sectionProps}
        trackingData={trackingData}
        isOverview={isOverview}
      />
    );
  }

  if (
    entityType !== ENTITY_TYPE.PRODUCTION &&
    [PHOTO_SECTION_TYPES.PAST, PHOTO_SECTION_TYPES.UPCOMING, PHOTO_SECTION_TYPES.PRODUCTION].includes(type)
  ) {
    let sectionTitle = `${TP}.FN_PRODUCTION_PHOTOS`;

    if (!title) {
      if (type === PHOTO_SECTION_TYPES.PAST) {
        sectionTitle = `${TP}.FN_PAST_PRODUCTION_PHOTOS`;
      } else if (type === PHOTO_SECTION_TYPES.UPCOMING) {
        sectionTitle = `${TP}.FN_UPCOMING_PRODUCTION_PHOTOS`;
      }
    }

    return (
      <ProductionPhotos
        title={title || t(sectionTitle)}
        type={type}
        limit={limit}
        distinctLimit={distinctLimit}
        infinite={infinite}
        sectionProps={sectionProps}
        isOverview={isOverview}
        trackingData={trackingData}
      />
    );
  }

  return null;
};

PhotoListing.propTypes = {
  type: PropTypes.oneOf([PHOTO_SECTION_TYPES.FEATURED, PHOTO_SECTION_TYPES.PAST, PHOTO_SECTION_TYPES.UPCOMING]),
  limit: PropTypes.number,
};

export default PhotoListing;
