/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';

import InfiniteListPage, { PageLoading } from 'components/Globals/Layout/InfiniteListPage';
import WorkTypeTag from 'components/Work/Display/WorkTypeTag';
import LinkButton from 'components/uiLibrary/LinkButton';
import SectionBlock from 'components/Globals/SectionBlock';
import EntityName from 'components/Globals/EntityName';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import SeparatorList from 'components/uiLibrary/SeparatorList';
import NoResult from 'components/Globals/NoResult';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';
import useTracking from 'components/Globals/Analytics';

import queries from 'containers/Work/queries';

import { useTranslation } from 'src/i18n';
import usePageContext from 'utils/hooks/usePageContext';

import { TP, ENTITY_TYPE, ENTITY_MAIN_TABS, PERIOD_VALUE } from 'constants/index';

import classes from './CreatorWorkList.module.scss';

const workListingTrackingDataSection = {
  section: SECTIONS.WORK_LISTING,
};
const seeAllWorksTrackingData = {
  ...workListingTrackingDataSection,
  component: COMPONENTS.SEE_ALL_CTA,
};
const workCardTrackingDataSlug = {
  ...workListingTrackingDataSection,
  component: COMPONENTS.WORK_CARD,
};

const WorkCard = ({ work, track }) => {
  const { entity, navigate } = usePageContext();
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { artistProfessions, upcomingPerformancesLinkProps, pastPerformancesLinkProps, linkProps } = useMemo(() => {
    const creatorProfessions = work?.creators?.reduce((acc, creator) => {
      if (creator?.profile?.id === entity?.id) {
        acc.push(creator?.profession?.name);
      }

      return acc;
    }, []);
    return {
      artistProfessions: [...new Set(creatorProfessions)],
      upcomingPerformancesLinkProps: navigate.getLinkProps({
        entity: work,
        entityType: ENTITY_TYPE.WORK,
        path: ENTITY_MAIN_TABS.PERFORMANCES,
        queryParams: { section: PERIOD_VALUE.UPCOMING },
      }),
      pastPerformancesLinkProps: navigate.getLinkProps({
        entity: work,
        entityType: ENTITY_TYPE.WORK,
        path: ENTITY_MAIN_TABS.PERFORMANCES,
      }),
      linkProps: navigate.getLinkProps({
        entity: work,
        entityType: ENTITY_TYPE.WORK,
      }),
    };
  }, [work, entity, navigate]);

  return (
    <div
      className={classes.card}
      onClick={() => {
        navigate.to(linkProps);
        track.click({
          ...workCardTrackingDataSlug,
          entityId: work?.id,
          entityName: work?.name,
          entityType: ENTITY_TYPE.WORK,
        });
      }}
    >
      <div className={classes.card__header}>
        <div>
          <WorkTypeTag tag={work?.workTypes?.[0]?.workType} />
        </div>
      </div>
      <div className={classes.card__content}>
        <div className={classes.card__content_details}>
          <LinkButton {...linkProps} variant="native" isLink skipTracking>
            <EntityName
              entity={work}
              entityType={ENTITY_TYPE.WORK}
              withComposers
              onlyShortName
              trackingData={workCardTrackingDataSlug}
            />
          </LinkButton>
          <Typography variant="p">
            <SeparatorList data={artistProfessions} />
          </Typography>
        </div>
        <div className={classes.card__content_icon}>
          <SpriteIcon size="18" icon="chevron_right" className={classes.icon} />
        </div>
      </div>
      <div className={classes.card__performances}>
        {work?.stats?.futureProductionCount > 0 && (
          <LinkButton
            {...upcomingPerformancesLinkProps}
            styles={{ root: classes.link }}
            variant="text"
            disableUnderline
            stopPropagation
            isLink
          >
            <Typography size="12" color="inherit">
              {t(`${TP}.m_ARTIST_UPCOMING_PERFOMANCES`)}
            </Typography>
            <Typography size="12" color="inherit" className={classes.count} />
            <Typography size="12" color="inherit">
              {work?.stats?.futureProductionCount}
            </Typography>
          </LinkButton>
        )}
        {work?.stats?.productionCount > 0 && (
          <LinkButton
            {...pastPerformancesLinkProps}
            styles={{ root: classes.link }}
            variant="text"
            isLink
            stopPropagation
          >
            <Typography size="12" color="inherit">
              {t(`${TP}.m_ARTIST_PAST_PERFORMANCES_FROM`, { year: 1996 })}
            </Typography>
            <Typography size="12" color="inherit" className={classes.count} />
            <Typography size="12" color="inherit">
              {work?.stats?.productionCount - (work?.stats?.futureProductionCount || 0)}
            </Typography>
          </LinkButton>
        )}
      </div>
    </div>
  );
};

const CreatorWorkList = ({
  entity,
  entityType = ENTITY_TYPE.ARTIST,
  limit,
  enableInfiniteScrolling = true,
  sectionProps = {},
  isOverview,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const track = useTracking();

  const { navigate } = usePageContext();

  const creationCount = entity?.stats ? entity?.stats?.creations?.composer?.total : entity?.creationCount;
  const linkProps = useMemo(() => navigate.getLinkProps({ entity, entityType, path: ENTITY_MAIN_TABS.WORKS }), [
    navigate,
    entity,
  ]);

  return (
    <SectionBlock
      title={t(`${TP}.FN_MUSICAL_WORKS_SECTION_NAME`)}
      count={creationCount}
      linkProps={{
        ...linkProps,
        title: t('VIEW_ALL_CTA', { title: t(`${TP}.FN_MUSICAL_WORKS_SECTION_NAME`) }),
      }}
      enableSeeAll={isOverview}
      enableViewAllButton={isOverview}
      {...sectionProps}
      seeAllTrackingData={seeAllWorksTrackingData}
    >
      <InfiniteListPage
        query={queries.getCreatorWorksDetails({ entityId: entity?.id, entityType, limit })}
        disabled={!enableInfiniteScrolling}
      >
        {({ pages, count, isLoading }) => (
          <>
            {!isLoading && count === 0 ? <NoResult title={t(`${TP}.NO_RESULTS`)} /> : null}
            {pages?.map(({ data }) => (
              <>
                {data?.map(work => (
                  <WorkCard key={work?.id} work={work} track={track} />
                ))}
              </>
            ))}
            <PageLoading defaultLoader />
          </>
        )}
      </InfiniteListPage>
    </SectionBlock>
  );
};

export default CreatorWorkList;
