import React from 'react';
import classnames from 'classnames';

import VideoPlayer from 'components/uiLibrary/VideoPlayer';
import JsonLD, { JSON_LD_TYPES } from 'components/Globals/JsonLD';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import MediaAttribution from 'components/Media/MediaAttribution';

import CardWithProductionInfo from '../CardWithProductionInfo';
import classes from './VideoCard.module.scss';

const VideoCard = ({ data, isLoading, mediaContent, fullWidth = false, withProductionInfo = false, trackingData }) => {
  if (isLoading) {
    return <Skeleton variant="rectangular" width={305} height={170} />;
  }

  return (
    <>
      <JsonLD type={JSON_LD_TYPES.VIDEO} entity={data} />
      <CardWithProductionInfo
        disabled={!withProductionInfo}
        production={data?.production}
        styles={{
          root: classnames(classes.card, {
            [classes.fullWidth]: fullWidth,
          }),
        }}
        trackingData={trackingData}
      >
        <VideoPlayer
          mediaContent={mediaContent}
          media={data}
          classNames={{ videoOverlay: classes.overlay }}
          showVideoInfo={!withProductionInfo}
          trackingData={trackingData}
        />
        <MediaAttribution
          className={classnames(classes.mediaAttribution, {
            [classes.withProductionInfo]: withProductionInfo && data?.production?.id,
          })}
          attribution={data}
        />
      </CardWithProductionInfo>
    </>
  );
};

export default VideoCard;
