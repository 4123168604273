import React, { useMemo } from 'react';

import SectionBlock from 'components/Globals/SectionBlock';
import ProductionListing, { VIEW_TYPES } from 'components/Productions/Common/ProductionListing';
import { useSeasonFilters } from 'components/Productions/Display/Seasons';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';
import { getValidFilters } from 'utils/globals/getRouteContext';
import { ENTITY_DETAILS_TAB, ENTITY_MAIN_TABS, TP } from 'constants/index';
import { FILTER_TYPES } from 'constants/filters';

import { useTranslation } from 'src/i18n';

import classes from './EntityPerformanceCredits.module.scss';

const performanceCreditsTrackingData = {
  section: SECTIONS.PERFORMANCE_CREDITS,
  component: COMPONENTS.PRODUCTION_SLUG,
};

const seeAllPerformanceTrackingData = {
  section: SECTIONS.PERFORMANCE_CREDITS,
  component: COMPONENTS.SEE_ALL_CTA,
};

const EntityPerformanceCredits = ({ entity, entityType, infinite = false, sectionProps = {}, isOverview }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();
  const { stats } = entity || {};
  const hasUpcomingPerformances = stats?.productions?.future?.exists;

  const upcomingPerformanceFilters = useSeasonFilters({ entityType, entity, upcoming: true });
  const pastPerformanceFilters = useSeasonFilters({ entityType, entity, upcoming: false });
  const filters = useMemo(() => {
    const { date_max: _dateMax, date_to: _dateTo, ...restFilters } = pastPerformanceFilters || {};

    return restFilters;
  }, [pastPerformanceFilters]);

  const linkProps = useMemo(() => {
    const baseLinkProps = navigate.getLinkProps({
      entity,
      entityType,
      path: ENTITY_MAIN_TABS.PERFORMANCES,
      title: t(`${TP}.FN_VIEW_ALL_UPCOMING_PRODUCTIONS_PERFORMANCES`),
    });
    const redirectToPast = stats?.productions?.past?.exists;

    if (redirectToPast) {
      return baseLinkProps.getSubPath({
        path: ENTITY_DETAILS_TAB.PAST,
        onlyLinkProps: true,
        title: t('VIEW_ALL_PAST_PRODUCTIONS_PERFORMANCES'),
      });
    }

    return baseLinkProps;
  }, [entity, entityType, navigate, stats?.productions?.future?.total, stats?.productions?.total, t]);

  const filterProps = useMemo(() => {
    const applicableFilters = getValidFilters({
      entityId: entity?.id,
      entityType,
      mainPath: ENTITY_MAIN_TABS.PERFORMANCES,
    })?.filter(type => type !== FILTER_TYPES.SEASON);

    return {
      types: applicableFilters,
      commonFilterParams: hasUpcomingPerformances ? upcomingPerformanceFilters : pastPerformanceFilters,
      mainPath: ENTITY_MAIN_TABS.PERFORMANCES,
      upcoming: hasUpcomingPerformances,
      clearExcludedTypes: [FILTER_TYPES.SEASON],
    };
  }, [hasUpcomingPerformances, upcomingPerformanceFilters, pastPerformanceFilters, entityType, entity]);

  return (
    <SectionBlock
      title={t(`${TP}.FN_CREDITS`)}
      description={t('CREDITS_DESCRIPTION')}
      linkProps={linkProps}
      count={stats?.productions?.total}
      enableViewAllButton={stats?.productions?.total > 5}
      enableSeeAll={isOverview}
      withTitle={isOverview}
      filterProps={filterProps}
      seeAllTrackingData={seeAllPerformanceTrackingData}
      {...sectionProps}
    >
      <div className={classes.root}>
        <ProductionListing
          entityType={entityType}
          entity={entity}
          infinite={infinite}
          viewMode={VIEW_TYPES.SEASON}
          filters={filters}
          limit={5}
          trackingData={performanceCreditsTrackingData}
        />
      </div>
    </SectionBlock>
  );
};

export default EntityPerformanceCredits;
