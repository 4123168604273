import React, { Fragment, useMemo, useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useQuery } from 'utils/react-query';
import find from 'lodash/find';
import head from 'lodash/head';
import PropTypes from 'prop-types';

import Typography from 'components/uiLibrary/Typography';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import Link from 'components/uiLibrary/Link'; // TODO: Change to use LinkButton

import queries from 'containers/Globals/queries';
import { TP, ENTITY_TYPE, ENTITY_MAIN_TABS } from 'constants/index';

import { useTranslation, i18n } from 'src/i18n';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import useBioDescriptions from 'utils/hooks/useBioDescriptions';

import classes from './EntityBioSummary.module.scss';
import SectionBlock from '../SectionBlock';

const trackingData = {
  section: SECTIONS.BIOGRAPHY,
};

const AvailableLanguages = ({ languageNames }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  return (
    <div>
      <Typography size={12} color="secondary" italic className={classes.bioLanguages}>
        ({t(`${TP}.FN_AVAILABLE_IN`)}: {languageNames})
      </Typography>
    </div>
  );
};

const ReadMore = ({ isTruncated }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  if (!isTruncated) {
    return null;
  }

  return (
    <Typography size={12} color="secondary" className={classes.readMoreLink}>
      ...{t(`${TP}.FN_READ_MORE`)}
    </Typography>
  );
};

const EntityBioSummary = ({ entity, entityType, isEditMode, sectionProps = {}, isOverview }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate, mainPath } = usePageContext();
  const { isDesktop } = useDeviceTypeLayouts();
  const [isTruncated, setIsTruncated] = useState(false);
  const excerptRef = useRef(null);

  const { bioContent, langOptions, language, isLoading: isOrgBioLoading } = useBioDescriptions({
    entity,
    entityType,
  });

  const { data: bio, isLoading: isIndBioLoading } = useQuery(
    queries.getEntityBioDetails({
      entityType,
      entityId: entity?.id,
      queryConfig: { enabled: entity?.stats?.about?.exists && entityType === ENTITY_TYPE.ARTIST },
    }),
  );

  const isLoading = ENTITY_TYPE.ARTIST === entityType ? isIndBioLoading : isOrgBioLoading;

  const bioInfo = useMemo(() => {
    if (isLoading) {
      return {};
    }

    if (entityType === ENTITY_TYPE.ARTIST) {
      const individualLang = typeof window === 'undefined' ? bio?.lang : i18n.language;
      const entityBio =
        find(bio?.data, { language: { lang: individualLang } }) ||
        find(bio?.data, { language: { lang: 'en' } }) ||
        head(bio?.data);

      return {
        subTitle: `${TP}.m_BIO`,
        linkProps: navigate.getLinkProps({
          entity,
          entityType,
          path: ENTITY_MAIN_TABS.BIO,
        }),
        content: entityBio?.excerpt,
        lang: individualLang,
        languageNames: bio?.data?.map(lang => lang?.language?.name)?.join(', '),
      };
    }

    return {
      subTitle: `${TP}.ABOUT`,
      linkProps: navigate.getLinkProps({
        entity,
        entityType,
        path: ENTITY_MAIN_TABS.ABOUT,
      }),
      content: bioContent,
      lang: language,
      languageNames: langOptions?.map(lang => lang?.language?.name)?.join(', '),
    };
  }, [isLoading, entityType, navigate, entity, bioContent, language, langOptions, bio?.lang, bio?.data]);

  const { subTitle, linkProps, content, lang, languageNames } = bioInfo;

  useEffect(() => {
    const { current } = excerptRef;
    if (current && content) {
      const isOverflowing = current.scrollHeight > current.clientHeight;
      setIsTruncated(isOverflowing);
    }
  }, [content]);

  if (isLoading) {
    return <Skeleton variant="rectangular" height={50} />;
  }

  if (!content) {
    return null;
  }

  const Wrapper = isOverview ? SectionBlock : Fragment;

  if (!isDesktop && isOverview && !isEditMode) {
    return (
      <Link
        {...linkProps}
        disableUnderline
        disableHover
        styles={{ root: classes.linkWrapper }}
        scroll
        trackingData={trackingData}
      >
        <div
          className={classnames(classes.title, { [classes.bioOverviewTitle]: isOverview })}
          ref={excerptRef}
          lang={lang}
        >
          <Typography variant="p" color="secondary" size="12">
            <Typography variant="span" weight="medium" size="12" inline color="secondary">
              {`${t(subTitle)}: `}
            </Typography>
            {content}
          </Typography>
          <ReadMore isTruncated={isTruncated} />
        </div>
      </Link>
    );
  }

  return (
    <Wrapper
      title={t(subTitle)}
      linkProps={linkProps}
      className={classnames(classes.bio, { [classes.bioOverview]: isOverview })}
      {...sectionProps}
      seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
    >
      <Link
        {...linkProps}
        disableUnderline
        disableHover
        className={classes.linkWrapper}
        scroll
        trackingData={trackingData}
      >
        {mainPath && !isOverview && (
          <Typography weight="medium" size="16" inline className={classes.subTitle}>
            {`${t(subTitle)} `}
          </Typography>
        )}
        <div
          className={classnames(classes.title, { [classes.bioOverviewTitle]: isOverview })}
          ref={excerptRef}
          lang={lang}
        >
          <Typography variant="p" color="secondary">
            {content}
          </Typography>
          <ReadMore isTruncated={isTruncated} />
        </div>
        <AvailableLanguages languageNames={languageNames} />
      </Link>
    </Wrapper>
  );
};

EntityBioSummary.propTypes = {
  entity: PropTypes.object,
  entityType: PropTypes.string,
};

export default EntityBioSummary;
