/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useCallback, Fragment } from 'react';
import Overflow from 'rc-overflow';
import classnames from 'classnames';
import LinkButton, { TertiaryButton } from 'components/uiLibrary/LinkButton';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import CastingToolPromo from 'components/Globals/CastingToolPromo';
import Typography from 'components/uiLibrary/Typography';
import EntityName from 'components/Globals/EntityName';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import PremiumPlanNudge from 'components/Globals/Layout/PremiumPlanNudge';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';
import { getMediaSource } from 'utils/media';
import { getLongProfessionDetails } from 'utils/artistUtils';
import { getLocation, useEntitySectionTabs } from 'utils/globals';
import { getH1FilterString } from 'utils/globals/filters';
import {
  ENTITY_TYPE,
  ENTITY_DETAILS_TAB,
  ENTITY_FEATURED_TABS_TITLE,
  ENTITY_MAIN_TABS,
  TP,
  TRACK_HEADER_TITLE_IMPRESSION,
  PREMIUM_NUDGE_VIEW_TYPES,
} from 'constants/index';
import { artistEditTabValues } from 'containers/Artist/constants';
import { ARTIST_PROFILE_INFO_SECTIONS } from 'constants/consts';

import { SECTIONS, COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import Actions from '../Actions';
import EditModeActions from '../EditModeActions';

import classes from './Header.module.scss';

const entityHeaderTrackingData = {
  section: SECTIONS.ENTITY_HEADER,
};

const artistNameTrackingData = {
  ...entityHeaderTrackingData,
  component: COMPONENTS.ARTIST_NAME,
};

const orgNameTrackingData = {
  ...entityHeaderTrackingData,
  component: COMPONENTS.ORGANIZATION_NAME,
};

const ProfileEditButton = ({ entityType, entity }) => {
  const { t } = useTranslation(['NS_ENTITY_STUB_PAGE']);
  const { isDesktop } = useDeviceTypeLayouts();
  const { navigate } = usePageContext();
  return (
    <TertiaryButton
      leftIcon={<SpriteIcon icon="edit" size={14} className={classes.editBtn__icon} />}
      {...navigate.getLinkProps({
        entityType,
        entity,
        edit: true,
        queryParams: { tab: artistEditTabValues.personal },
        scrollTo: ARTIST_PROFILE_INFO_SECTIONS.PROFILE_NAME,
      })}
      isLink
      size={isDesktop ? 'medium' : 'small'}
      styles={{
        root: classes.editBtn,
      }}
      trackingData={{
        ...artistNameTrackingData,
        subComponent: SUB_COMPONENTS.EDIT_PROFILE_CTA,
      }}
    >
      {!isDesktop ? t(`${TP}.m_EDIT`) : ''}
    </TertiaryButton>
  );
};

const RenderProfession = ({ professionTypes, entity, isHeaderVisible, isEditMode = false }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();
  const { isMobile, isTablet } = useDeviceTypeLayouts();

  return (
    <Overflow
      className={classes.artistProfession}
      data={professionTypes}
      renderItem={(item, index) => (
        <Typography
          color="secondary"
          className={classnames(classes.item, { [classes.itemSticky]: !isHeaderVisible })}
          key={`${item}_${index}`}
        >
          {item}
        </Typography>
      )}
      renderRest={items =>
        isEditMode ? (
          <>
            &nbsp;({`+${items?.length}`} {t(`${TP}.MORE`)})
          </>
        ) : (
          <LinkButton
            variant="text"
            styles={{ root: classes.moreBtn }}
            {...navigate.getLinkProps({
              entity,
              entityType: ENTITY_TYPE.ARTIST,
              path: ENTITY_MAIN_TABS.BIO,
            })}
            isLink={entity?.stats?.about?.exists}
            disableUnderline
          >
            <Typography
              color="secondary"
              className={classnames(classes.moreItem, { [classes.moreItem_sticky]: !isHeaderVisible })}
            >
              ({`+${items?.length}`} {t(`${TP}.MORE`)})
            </Typography>
          </LinkButton>
        )
      }
      // eslint-disable-next-line no-nested-ternary
      maxCount={isEditMode ? (isMobile ? 1 : 'responsive') : isMobile ? 1 : isTablet ? 4 : 5}
    />
  );
};

const useGetContentInfo = (entity, entityType, isHeaderVisible, isEditMode) => {
  const { label, subtext } = useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPE.ARTIST: {
        const deathYear = entity?.personal?.death?.year;
        const birthYear = entity?.personal?.birth?.year;

        return {
          label: deathYear && birthYear ? `${birthYear}-${deathYear}` : '',
          subtext: (
            <RenderProfession
              professionTypes={getLongProfessionDetails(entity)}
              entity={entity}
              isHeaderVisible={isHeaderVisible}
              isEditMode={isEditMode}
            />
          ),
        };
      }
      case ENTITY_TYPE.ORGANIZATION: {
        return {
          subtext: entity?.organizationType?.name,
          label: getLocation(entity, { includeAreas: false }),
        };
      }
      default: {
        return {};
      }
    }
  }, [entity, entityType, isEditMode, isHeaderVisible]);
  return { label, subtext };
};

const useGetTabName = ({ mainPath, entityType, entity, subPath, filterParams, isEditMode }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const tabs = useEntitySectionTabs({ entityType, entity, isEditMode });
  const tabName = useMemo(() => {
    if (!mainPath) {
      return '';
    }

    const activeTab = tabs?.find(({ key }) => key === mainPath);
    if (
      [
        ENTITY_DETAILS_TAB.GENERAL,
        ENTITY_DETAILS_TAB.DIGITAL,
        ENTITY_DETAILS_TAB.INTRO_SHOWREEL,
        ENTITY_DETAILS_TAB.AUDITION_SHOWREEL,
      ].includes(subPath)
    ) {
      return ` / ${t(ENTITY_FEATURED_TABS_TITLE[subPath])} ${t(activeTab?.title)}`;
    }

    if (
      activeTab?.key === ENTITY_MAIN_TABS.PERFORMANCES &&
      subPath !== ENTITY_DETAILS_TAB.PAST &&
      !filterParams?.season_id?.[0]
    ) {
      return ` / ${t(`${TP}.FN_UPCOMING_PERFORMANCES`)}`;
    }

    if (
      activeTab?.key === ENTITY_MAIN_TABS.PERFORMANCES &&
      subPath == ENTITY_DETAILS_TAB.PAST &&
      !filterParams?.season_id?.[0]
    ) {
      return ` / ${t(`${TP}.FN_PAST_PERFORMANCES`)}`;
    }

    if (activeTab) {
      return ` / ${t(activeTab?.title)}`;
    }

    return '';
  }, [mainPath, subPath, tabs, filterParams, t]);
  return tabName;
};

/** reason behind passing isHeaderVisible as props is because now we are 
controlling header visibility from BaseWrapper component scrolls positions */

const Header = ({ entity, entityType, hideActions, isHeaderVisible, isEditMode, isSticky }) => {
  const { navigate, mainPath, appliedFilterSlugs, subPath, filterParams } = usePageContext();
  const { isMobile, isDesktop } = useDeviceTypeLayouts();
  const tabName = useGetTabName({ mainPath, entityType, entity, subPath, filterParams, isEditMode });
  const { label, subtext } = useGetContentInfo(entity, entityType, isHeaderVisible, isEditMode);
  const hasPremiumSubscription = entityType === ENTITY_TYPE.ARTIST && entity?.stats?.isPro;

  const getImageDimensions = useCallback(() => {
    if (!isHeaderVisible) {
      return { height: 44, width: 44 };
    }
    if (isMobile) {
      return { height: 32, width: 32 };
    }
    return { height: 57, width: 57 };
  }, [isHeaderVisible, isMobile]);

  const { imgProps, dimensions } = useMemo(() => {
    const { height, width } = getImageDimensions();
    const imgWidth = width;
    const imgHeight = height;
    const BORDER_FIX = 2;
    const imageWrapperWidth = entity?.image || entity?.logo ? imgWidth + BORDER_FIX : 0;
    return {
      imgProps: {
        transformations:
          entityType === ENTITY_TYPE.ARTIST ? TRANSFORMATIONS.PROFILE_THUMBNAIL : TRANSFORMATIONS.AOS_LOGO,
        height: imgWidth,
        width: imgHeight,
        disableNextImage: true,
        useIntersectionObserver: true,
        alt: entity?.name,
        title: entity?.name,
      },
      dimensions: {
        image: {
          height: imgHeight + BORDER_FIX,
          width: imageWrapperWidth,
        },
        content: {
          width: `calc(100% - ${imageWrapperWidth}px)`,
        },
      },
    };
  }, [entityType, getImageDimensions, entity]);

  const appliedFilters = useMemo(() => getH1FilterString({ filters: appliedFilterSlugs, tabKey: mainPath }), [
    appliedFilterSlugs,
    mainPath,
  ]);
  const linkProps = navigate.getLinkProps({ entityType, entity, onlyLinkProps: true });
  const src = getMediaSource(
    {
      id: entity?.id,
      file: { urls: entity?.image || entity?.logo },
    },
    true,
  );

  const hasMatchSubPath = [
    ENTITY_DETAILS_TAB.GENERAL,
    ENTITY_DETAILS_TAB.DIGITAL,
    ENTITY_DETAILS_TAB.INTRO_SHOWREEL,
    ENTITY_DETAILS_TAB.AUDITION_SHOWREEL,
  ].includes(subPath);

  const Wrapper = isEditMode ? 'div' : Fragment;

  return (
    <div className={classes.root}>
      <div
        className={classnames(classes.mainSection, {
          [classes.mainSection__sticky]: isSticky,
          [classes.mainSection__sticky_org]: entityType === ENTITY_TYPE.ORGANIZATION,
          [classes.mainSection__editMode]: isEditMode,
          [classes.mainSection__isStickyInEditMode]: isEditMode && !isDesktop && isSticky,
        })}
      >
        <Wrapper
          {...(isEditMode && {
            className: classnames(classes.wrapper, {
              [classes.wrapper__isStickyInEditMode]: isEditMode && !isDesktop && isSticky,
              [classes.wrapper__isStickyInEditMode_isPremiumUser]:
                isEditMode && !isDesktop && isSticky && hasPremiumSubscription,
            }),
          })}
        >
          {hasMatchSubPath && (
            <span className={classes.backBtn} onClick={() => navigate.back()}>
              <SpriteIcon icon="arrow_back" />
            </span>
          )}
          {src && !isEditMode && (
            <LinkButton
              styles={{
                root: classnames(classes.profileLink, classes.profileLink__img, {
                  [classes.profileLink__sticky]: !isHeaderVisible,
                }),
              }}
              variant="native"
              {...linkProps}
              trackingData={{
                entityId: entity?.id,
                entityName: entity?.name,
                entityType,
                ...entityHeaderTrackingData,
                component: entityType === ENTITY_TYPE.ARTIST ? COMPONENTS.ARTIST_IMAGE : COMPONENTS.ORGANIZATION_IMAGE,
                meta: {
                  tab: mainPath || ENTITY_MAIN_TABS.HOME,
                },
              }}
              isLink
            >
              <div className={classes.profileImage} style={dimensions?.image}>
                <Image src={src} {...imgProps} />
              </div>
            </LinkButton>
          )}
          <div
            className={classnames(classes.headingSection, {
              [classes.headingSection__editMode]: isEditMode,
              [classes.headingSection__editMode_isOverview]: isEditMode && !tabName && !isSticky,
            })}
          >
            <div className={classes.headingSection__wrapper}>
              <LinkButton
                styles={{
                  root: classnames({
                    [classes.titleLink__isEditMode]: !!isEditMode,
                    [classes.titleLink__sticky]: isSticky,
                    [classes.titleLink__sticky_isEditMode]: !!isEditMode && isSticky,
                  }),
                }}
                variant="native"
                {...linkProps}
                isLink
                trackingData={{
                  entityId: entity?.id,
                  entityName: entity?.name,
                  entityType,
                  ...(entityType === ENTITY_TYPE.ARTIST ? artistNameTrackingData : orgNameTrackingData),
                }}
              >
                <Typography
                  variant={isSticky ? 'p' : 'h1'}
                  className={classes.title}
                  size={isSticky ? 24 : 32}
                  weight="bold"
                  {...((isSticky || !isHeaderVisible || (isEditMode && mainPath)) && { truncate: true })}
                >
                  <EntityName
                    entityType={entityType}
                    entity={entity}
                    eventType={TRACK_HEADER_TITLE_IMPRESSION(entityType)}
                    trackingData={entityType === ENTITY_TYPE.ARTIST ? artistNameTrackingData : orgNameTrackingData}
                    isRaw
                  />
                  {isHeaderVisible ? tabName : ''}
                  {!isSticky && isHeaderVisible && appliedFilters?.length > 0 && ` / ${appliedFilters}`}
                </Typography>
              </LinkButton>
              {!hideActions && !isEditMode && (
                <Actions
                  entity={entity}
                  entityType={entityType}
                  isEditMode={isEditMode}
                  trackingData={{
                    ...entityHeaderTrackingData,
                    meta: {
                      tab: mainPath || ENTITY_MAIN_TABS.HOME,
                    },
                  }}
                />
              )}
            </div>
            <div
              className={classnames(classes.subtextWrapper, {
                [classes.subtextWrapper__isEditMode]: isEditMode,
              })}
            >
              <Typography
                size={isSticky ? 14 : 18}
                color="secondary"
                className={classes.subtext}
                {...((isMobile || isEditMode) && { truncate: true })}
              >
                {subtext}
                {subtext && label && ' | '}
                {label}
              </Typography>
            </div>
          </div>
          {isEditMode && !isSticky && !mainPath && <ProfileEditButton entityType={entityType} entity={entity} />}
        </Wrapper>
        {!hasPremiumSubscription && isEditMode && isSticky && !isDesktop && (
          <PremiumPlanNudge
            type={PREMIUM_NUDGE_VIEW_TYPES.CONCISE}
            styles={{ root: classes.premiumPlanNudge }}
            trackingData={entityType === ENTITY_TYPE.ARTIST ? artistNameTrackingData : orgNameTrackingData}
          />
        )}
        {isEditMode && isDesktop && (
          <EditModeActions
            entityType={entityType}
            entity={entity}
            trackingData={entityType === ENTITY_TYPE.ARTIST ? artistNameTrackingData : orgNameTrackingData}
          />
        )}
      </div>
      {entityType === ENTITY_TYPE.ARTIST && !isHeaderVisible && !isEditMode && (
        <div className={classes.castingToolCTA}>
          <CastingToolPromo
            entityType={entityType}
            entity={entity}
            styles={{
              castingToolCta: classes.castingToolCta,
            }}
            asCTA
            trackingData={{
              ...entityHeaderTrackingData,
              component: COMPONENTS.VIEW_IN_CASTING_TOOL_CTA,
              meta: {
                tab: mainPath || ENTITY_MAIN_TABS.HOME,
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
