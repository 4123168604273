import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'utils/react-query';
import classnames from 'classnames';

import SectionBlock from 'components/Globals/SectionBlock';
import ClaimProfileCard from 'components/Artist/Display/ClaimProfileCard';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';

import { TP, ENTITY_TYPE, PREMIUM_NUDGE_VIEW_TYPES, POPUP_SOURCES } from 'constants/index';
import queries from 'containers/Globals/queries';

import { useTranslation } from 'src/i18n';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';

import PreviewCard from '../PreviewCard';
import EntityCard from '../EntityCard';

import classes from './EntitySuggestions.module.scss';

const getLimit = (entityType, inline, isLoggedIn) => {
  if (entityType === ENTITY_TYPE.ARTIST && inline) {
    return isLoggedIn ? 7 : 5;
  }

  return 10;
};

const EntitySuggestions = ({
  entityType,
  inline = false,
  styles,
  trackingData,
  claimProfileTrackingData,
  isEditMode,
  entity,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { entityId, permissions } = usePageContext();
  const { isMobile, isTablet } = useDeviceTypeLayouts();
  const showPaidSection = entity?.stats?.isPro;
  const isLoggedIn = permissions?.isLoggedIn;

  const query = useMemo(() => {
    const queryFilters = {
      ...(entityType && { entityType }),
      ...(entityId && { entityId }),
      limit: getLimit(entityType, inline, isLoggedIn),
      queryConfig: {
        enabled: !!entityId,
      },
    };

    if (inline || entityType !== ENTITY_TYPE.ARTIST) {
      return queries.getEntityRecommendations(queryFilters);
    }

    return queries.getProfilesViewed(queryFilters);
  }, [entityType, entityId, inline, isLoggedIn]);

  const { data: response, isLoading } = useQuery(query);

  const showClaimProfileCard = inline && !isLoggedIn;

  if (isLoading) {
    return <Skeleton variant="rectangular" height={inline ? 44 : 260} className={classes.skeleton} />;
  }

  return (
    <div className={classnames({ [classes.inline_wrapper]: inline })}>
      <SectionBlock
        {...(!inline && { title: t(`${TP}.FN_PEOPLE_ALSO_VIEWED`) })}
        {...(isEditMode && inline && { title: t(`${TP}.FN_SUGGESTED_ARTISTS`) })}
        className={classnames({
          [classes.sectionBlock]: !!inline,
          [styles?.sectionBlock]: !!styles?.sectionBlock,
        })}
        withTitle={!inline || !!isEditMode}
        hideSectionDivider
        {...(isEditMode &&
          inline && {
            ...(!showPaidSection && {
              premiumSection: {
                enabled: true,
                hideDescriptionIcon: true,
                type: isMobile ? PREMIUM_NUDGE_VIEW_TYPES.DEFAULT : PREMIUM_NUDGE_VIEW_TYPES.INLINE,
                description: t(`${TP}.FN_OTHER_ARTIST_SECTION_DESCRIPTION`),
                isHidden: true,
                nudgeSourceType: POPUP_SOURCES.ARTIST_SUGGESTIONS || '',
                trackingData,
                styles: {
                  root: classes.premiumNudge,
                },
              },
              showHideProps: {
                enabled: true,
                value: true,
                specialPermission: { permission: false },
                popupSource: POPUP_SOURCES.ARTIST_SUGGESTIONS,
                trackingData,
              },
              styles: {
                gradient: classes.sectionBlock__gradient,
                header: classes.sectionBlockHeader,
                leftBoxIcon: classes.sectionBlockHeader__leftBoxIcon,
              },
            }),
          })}
      >
        <HorizontalScrollIndicators
          className={classnames(classes.horizontalScrollItems, {
            [styles?.horizontalScroll]: !!styles?.horizontalScroll,
          })}
          triggerSize={0}
          isArrowsEnabled={!isMobile}
        >
          {response?.data?.map((data, index) =>
            inline ? (
              <Fragment key={data?.id}>
                <EntityCard
                  key={data?.id}
                  entity={data}
                  entityType={entityType}
                  width={30}
                  height={30}
                  transformations={TRANSFORMATIONS.PROFILE_THUMBNAIL}
                  typographyProps={{
                    name: {
                      truncate: true,
                    },
                    subtext: {
                      truncate: true,
                    },
                  }}
                  className={classes.inline_card}
                  noFollow
                  trackingData={trackingData}
                />
                {showClaimProfileCard && isMobile && index === 0 && (
                  <ClaimProfileCard trackingData={claimProfileTrackingData} />
                )}
                {showClaimProfileCard && isTablet && index === 3 && (
                  <ClaimProfileCard trackingData={claimProfileTrackingData} />
                )}
              </Fragment>
            ) : (
              <PreviewCard key={data?.id} entityType={entityType} entity={data} trackingData={trackingData} />
            ),
          )}
          {showClaimProfileCard && (
            <div className={classes.claimProfileCard}>
              <ClaimProfileCard trackingData={claimProfileTrackingData} />
            </div>
          )}
        </HorizontalScrollIndicators>
      </SectionBlock>
    </div>
  );
};

EntitySuggestions.propTypes = {
  entityType: PropTypes.string,
  entity: PropTypes.object,
};

export default EntitySuggestions;
