// TODO: Rearrange this file
import { useEffect, useState, useCallback } from 'react';
import { useQuery } from 'utils/react-query';
import head from 'lodash/head';
import orgQueries from 'containers/Organizations/queries';

import { i18n } from 'src/i18n';
import { swap } from 'utils/common';
import { getOption } from 'utils/globals/app';
import { ENTITY_TYPE } from 'constants/index';

const useBioDescriptions = ({ entity, entityType, withLanguage = true }) => {
  const [chosenLang, setLang] = useState(null);
  const [langOptions, setOptions] = useState([]);
  const [bioContent, setBio] = useState(null);

  const { data: bioData, isFetching } = useQuery(
    orgQueries.getTranslations({
      id: entity?.id,
      queryConfig: {
        enabled: entityType === ENTITY_TYPE.ORGANIZATION && !!entity?.stats?.about?.exists,
        select: response => ({
          ...response,
          data: response?.data?.filter(item => item?.content || item?.description),
        }),
      },
    }),
  );

  useEffect(() => {
    if (bioData?.data?.length === 0 || !withLanguage) {
      return;
    }
    const descriptions = bioData?.data;
    const defaultBio = head(descriptions);
    const lang = typeof window === 'undefined' ? bioData?.lang || 'en' : i18n.language;

    const data = descriptions?.filter(val => val.content || val.description);
    const currentLangIndex = data?.findIndex(x => x.language.lang === lang);
    if (currentLangIndex > 0) {
      swap(data, 0, currentLangIndex);
    }
    const langsOptions = data?.map(b => ({ ...b, ...getOption(b?.language, 'id', 'name', { id: b?.language?.id }) }));
    setBio(langsOptions?.[0]?.content || langsOptions?.[0]?.description);
    setOptions(langsOptions);
    setLang(langsOptions?.[0]);

    if (currentLangIndex === -1) {
      setBio(defaultBio?.content || defaultBio?.description);
    }
  }, [bioData?.data, bioData?.lang, withLanguage]);

  const onChangeLang = useCallback(
    (e, { value }) => {
      const selectedOption = langOptions?.find(option => option?.value === value);
      setLang(selectedOption);
      setBio(selectedOption?.description || selectedOption?.content);
    },
    [langOptions],
  );

  return {
    onChangeLang,
    chosenLang,
    bioContent,
    isLoading: isFetching,
    langOptions,
    language: chosenLang?.language?.lang,
    hasBio: bioData?.data?.length > 0,
  };
};

export default useBioDescriptions;
